import React from "react";

const App = () => {
  return (
    <div className="App">
      <article>
        <h1>TAMS 2025</h1>
      </article>
      <style>{`
        @property --color-1 {
          syntax: "<color>";
          inherits: false;
          initial-value: hsl(98 100% 62%);
        }

        @property --color-2 {
          syntax: "<color>";
          inherits: false;
          initial-value: hsl(204 100% 59%);
        }

        @keyframes gradient-change {
          to {
            --color-1: hsl(210 100% 59%);
            --color-2: hsl(310 100% 59%);
          }
        }

        article {
          animation: gradient-change 2s linear infinite alternate;
          background: linear-gradient(to right in oklch, var(--color-1), var(--color-2));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          color: transparent;
        }

        @layer demo.support {
          h1 {
            font-size: 10vmin;
            line-height: 1.1;
          }

          body {
            background: hsl(204 100% 5%);
            min-block-size: 100%;
            box-sizing: border-box;
            display: grid;
            place-content: center;
            font-family: system-ui;
            font-size: min(200%, 4vmin);
            padding: 5vmin;
          }

          h1, p, body {
            margin: 0;
            text-wrap: balance;
          }

          h1 {
            line-height: 1.25cap;
          }

          p {
            font-family: "Dank Mono", ui-monospace, monospace;
          }

          html {
            block-size: 100%;
          }

          article {
            display: grid;
            gap: 1lh;
            text-align: center;
          }
        }
      `}</style>
    </div>
  );
};

export default App;
